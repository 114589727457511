import { createSlice } from "@reduxjs/toolkit";
import { func } from "prop-types";
// utils
import axios, { API_ENDPOINTS } from "src/utils/axios";

const initialState = {
  applicants: null,
  applicantsStatus:{
    isLoading: false,
    error: null,
  }
};

const slice = createSlice({
  name: 'applicant',
  initialState,
  reducers: {

    // JOB
    getApplicantsStart(state) {
      state.applicantsStatus.isLoading = true;
      state.applicantsStatus.error = null;
    },
    getApplicantsFailure(state,action) {
      state.applicantsStatus.isLoading = false;
      state.applicantsStatus.error = action.payload;
    },
    getApplicantsSuccess(state,action) {
      state.applicants = action.payload;
      state.applicantsStatus.isLoading = false;
      state.applicantsStatus.error = null;
    },
  },
});

export default slice.reducer;

export const getApplicants = (jobId) => async (dispatch) => {
  dispatch(slice.actions.getApplicantsStart());
  try {
    const response = await axios.get(`${API_ENDPOINTS.applicant.list}/${jobId}`);
    if(response.data.error)
      response.data = {data:[]}
    dispatch(slice.actions.getApplicantsSuccess(response.data.data));
  } catch (error) {
    dispatch(slice.actions.getApplicantsFailure(error));
  }
};

export const clearApplicants = () => async (dispatch) =>{
  try {
    dispatch(slice.actions.getApplicantsSuccess(null));
  } catch (error) {
    dispatch(slice.actions.getApplicantsFailure(error));
  }
}
