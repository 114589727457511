import {createSlice} from '@reduxjs/toolkit';
// utils
import axios, {API_ENDPOINTS} from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  profiles: [], profile: null, attributes: {}, profilesStatus: {
    loading: false, empty: false, error: null,
  }, profileStatus: {
    loading: false, error: null,
  }, steps: {
    activeStep: 1
  }
};

const slice = createSlice({
  name: 'profile', initialState, reducers: {
    // GET PROFILES
    getProfilesStart(state) {
      state.profilesStatus.loading = true;
      state.profilesStatus.empty = false;
      state.profilesStatus.error = null;
    }, getProfilesFailure(state, action) {
      state.profilesStatus.loading = false;
      state.profilesStatus.empty = false;
      state.profilesStatus.error = action.payload;
    }, getProfilesSuccess(state, action) {
      const profiles = action.payload;

      state.profiles = profiles;

      state.profilesStatus.loading = false;
      state.profilesStatus.empty = !profiles.length;
      state.profilesStatus.error = null;
    },

    // GET PROFILE
    getProfileStart(state) {
      state.profileStatus.loading = true;
      state.profileStatus.error = null;
    }, getProfileFailure(state, action) {
      state.profileStatus.loading = false;
      state.profileStatus.error = action.payload;
    },
    getProfileSuccess(state, action) {
      state.profile = action.payload;
      state.profileStatus.loading = false;
      state.profileStatus.error = null;
    },

    backStep(state) {
      state.step.activeStep -= 1;
    }, nextStep(state) {
      state.step.activeStep += 1;
    }, gotoStep(state, action) {
      state.step.activeStep = action.payload;
    },

    // GET PROFILE ATTRIBUTES
    getAttributesSuccess(state, action) {
      const {role, data} = action.payload;
      state.attributes[role] = data[0]
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  gotoStep, backStep, nextStep,
} = slice.actions;

// ----------------------------------------------------------------------


export function getProfiles(params) {
  return async (dispatch) => {
    dispatch(slice.actions.getProfilesStart());
    try {
      const response = await axios({
        method: "get", url: API_ENDPOINTS.profile.list, params
      });
      dispatch(slice.actions.getProfilesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.getProfilesFailure(error));
    }
  };
}

export function clearProfiles(){
  return async (dispatch) => {
    try{
      dispatch(slice.actions.getProfilesSuccess({}));
    } catch (error) {
      dispatch(slice.actions.getProfilesFailure(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProfile(profileId) {

  return async (dispatch) => {
    dispatch(slice.actions.getProfileStart());
    try {
      const response = await axios.get(API_ENDPOINTS.profile.details, {
        params: {
          id: profileId,
        },
      });
      dispatch(slice.actions.getProfileSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.getProfileFailure(error));
    }
  };
}

export async function getProfileWD(profileId) {

  try {
    
    const response = await axios.get(API_ENDPOINTS.profile.details, {
      params: {
        id: profileId,
      },
    });

    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export function clearProfile(){
  return async (dispatch) => {
    try{
      dispatch(slice.actions.getProfileSuccess(null));
    } catch (error) {
      dispatch(slice.actions.getProfileFailure(error));
    }
  };
}

export function updateProfile(body, section) {
  return async (dispatch) => {
    dispatch(slice.actions.getProfileStart());
    try {
      
      const response = await axios.put(API_ENDPOINTS.profile.update[section], body);
      
      dispatch(slice.actions.getProfileSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.getProfileFailure(error));
    }
  }
}

// ----------------------------------------------------------------------

export function getAttributesForRoles(roles) {
  return async (dispatch) => {
    try {
      roles.forEach(async role => {
        const response = await axios.get(API_ENDPOINTS.attributes.list, {
          params: {role},
        });
        // console.log("Fetched response for", role)
        dispatch(slice.actions.getAttributesSuccess({data: response.data, role}));
      })
    } catch (e) {
      console.log(e)
    }
  }
}


// export function updateProfileDetails(profileId, body) {
//   return async (dispatch) => {
//     dispatch(slice.actions.getProfileStart());
//     try {
//       const response = await axios.put(`${HOST_API}/admin/profile/profile/${profileId}`, body);
//       dispatch(slice.actions.getProfileSuccess(response.data));
//     } catch (error) {
//       console.error(error);
//       dispatch(slice.actions.getProfileFailure(error));
//     }
//   }
// }


export function uploadLogoImage(profileId, file) {
  return async (dispatch) => {
    dispatch(slice.actions.getProfileStart());

    const formData = new FormData();
    formData.append(`file`, file);
    formData.append(`id`, profileId);

    try {
      const response = await axios.patch(API_ENDPOINTS.profile.update.uploadLogo, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      await dispatch(getProfile(profileId));

      dispatch(slice.actions.getProfileSuccess(response.data.data));
    } catch (error) {
      console.error('Error uploading image:', error);
      dispatch(slice.actions.getProfileFailure(error));
    }
  };
}

export function uploadImage(profileId, files) {
  return async (dispatch) => {
    dispatch(slice.actions.getProfileStart());

    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append(`file`, file);
      formData.append(`id`, profileId);
    });

    try {
      const response = await axios.post(API_ENDPOINTS.profile.update.addImage, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      dispatch(slice.actions.getProfileSuccess(response.data.data));

    } catch (error) {
      console.error('Error uploading image:', error);
      dispatch(slice.actions.getProfileFailure(error));
    }
  };
}

export function deleteImage(profileId, image) {
  return async (dispatch) => {
    dispatch(slice.actions.getProfileStart());
    try {
      const response = await axios.delete(API_ENDPOINTS.profile.update.deleteImage, {
        data: {id: profileId, image},
      });
      dispatch(slice.actions.getProfileSuccess(response.data.data));
    } catch (error) {
      console.error('Error deleting image:', error);
      dispatch(slice.actions.getProfileFailure(error));
    }
  };
}

export function uploadVideo(profileId, videoUrl) {
  return async (dispatch) => {
    dispatch(slice.actions.getProfileStart());

    const videoIdMatch = videoUrl.match(/[?&]v=([^?&]+)/);
    const videoId = videoIdMatch ? videoIdMatch[1] : null;
    try {
      const response = await axios.post(API_ENDPOINTS.profile.update.addVideoUrl, {
        id: profileId, videoUrl: {
          url: videoUrl, id: videoId, type: 'youtube',
        },
      });

      dispatch(slice.actions.getProfileSuccess(response.data.data));
    } catch (error) {
      console.error('Error adding/updating video:', error);
      dispatch(slice.actions.getProfileFailure(error));
    }
  };
}

export function deleteVideo(profileId, videoUrl) {
  return async (dispatch) => {
    dispatch(slice.actions.getProfileStart());
    try {
      const response = await axios.delete(API_ENDPOINTS.profile.update.deleteVideoUrl, {
        data: {videoUrl, id: profileId},
      });
      dispatch(slice.actions.getProfileSuccess(response.data.data));
    } catch (error) {
      console.error('Error deleting video:', error.response.data);
      dispatch(slice.actions.getProfileFailure(error));
    }
  };
}

export function addExperience(profileId, experienceData) {
  return async (dispatch) => {
    dispatch(slice.actions.getProfileStart());
    try {
      const response = await axios.put(API_ENDPOINTS.profile.update.addExperience, {
        id: profileId, ...experienceData,
      });
      dispatch(slice.actions.getProfileSuccess(response.data));
    } catch (error) {
      console.error('Error adding experience:', error.response.data);
      dispatch(slice.actions.getProfileFailure(error));
    }
  };
}

export function updateExperience(profileId, experienceId, experienceData) {
  return async (dispatch) => {
    dispatch(slice.actions.getProfileStart());
    try {
      const response = await axios.put(API_ENDPOINTS.profile.update.updateExperience, {
        id: profileId, experienceId, ...experienceData,
      });
      dispatch(slice.actions.getProfileSuccess(response.data));
    } catch (error) {
      console.error('Error updating experience:', error.response.data);
      dispatch(slice.actions.getProfileFailure(error));
    }
  };
}

export function deleteExperience(profileId, experienceId) {
  return async (dispatch) => {
    dispatch(slice.actions.getProfileStart());
    try {
      const response = await axios.delete(`${API_ENDPOINTS.profile.update.deleteExperience}/${profileId}/${experienceId}`);
      dispatch(slice.actions.getProfileSuccess(response.data));
    } catch (error) {
      console.error('Error deleting experience:', error.response.data);
      dispatch(slice.actions.getProfileFailure(error));
    }
  };
}
