import {createSlice} from '@reduxjs/toolkit';
// utils
import axios, {API_ENDPOINTS} from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  cities: [],
  citiesStatus: {
    loading: false,
    empty: false,
    error: null,
  },
  states: [],
  statesStatus: {
    loading: false,
    empty: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'city',
  initialState,
  reducers: {
    // GET CITIES
    getCitiesStart(state) {
      state.citiesStatus.loading = true;
      state.citiesStatus.empty = false;
      state.citiesStatus.error = null;
    },
    getCitiesFailure(state, action) {
      state.citiesStatus.loading = false;
      state.citiesStatus.empty = false;
      state.citiesStatus.error = action.payload;
    },
    getCitiesSuccess(state, action) {
      const cities = action.payload;
      state.cities = cities;

      state.citiesStatus.loading = false;
      state.citiesStatus.empty = !cities.length;
      state.citiesStatus.error = null;
    },
    // GET STATES
    getStatesStart(state) {
      state.statesStatus.loading = true;
      state.statesStatus.empty = false;
      state.statesStatus.error = null;
    },
    getStatesFailure(state, action) {
      state.statesStatus.loading = false;
      state.statesStatus.empty = false;
      state.statesStatus.error = action.payload;
    },
    getStatesSuccess(state, action) {
      const states = action.payload;
      state.states = states;

      state.statesStatus.loading = false;
      state.statesStatus.empty = !states.length;
      state.statesStatus.error = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getCities(params) {
  return async (dispatch) => {
    dispatch(slice.actions.getCitiesStart());
    try {
      const response = await axios({
        method: "get",
        url: API_ENDPOINTS.city.list,
        params
      });
      dispatch(slice.actions.getCitiesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.getCitiesFailure(error));
    }
  };
}

export function getStates(params) {
  return async (dispatch) => {
    dispatch(slice.actions.getStatesStart());
    try {
      const response = await axios({
        method: "get",
        url: API_ENDPOINTS.city.states,
        params
      });
      dispatch(slice.actions.getStatesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.getStatesFailure(error));
    }
  };
}
