import { createSlice } from '@reduxjs/toolkit';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

// ----------------------------------------------------------------------

export const initialState = {
  users: [],
  user: null,
  usersStatus: {
    loading: false,
    empty: false,
    error: null,
  },
  userStatus: {
    loading: false,
    error: null,
  },
  aspirantTypes: [],
  categories:[],
  userCrud:false
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // GET userS
    getUsersStart(state) {
      state.usersStatus.loading = true;
      state.usersStatus.empty = false;
      state.usersStatus.error = null;
    },
    getUsersFailure(state, action) {
      state.usersStatus.loading = false;
      state.usersStatus.empty = false;
      state.usersStatus.error = action.payload;
    },
    getUsersSuccess(state, action) {
      const users = action.payload;

      state.users = users;

      state.usersStatus.loading = false;
      state.usersStatus.empty = !users.length;
      state.usersStatus.error = null;
    },

    // GET user
    resetUserAction(state){
        state.user = null;
    },
    getUserstart(state) {
      state.userStatus.loading = true;
      state.userStatus.error = null;
    },
    getUserFailure(state, action) {
      state.userStatus.loading = false;
      state.userStatus.error = action.payload;
    },
    getUsersuccess(state, action) {
      const user = action.payload;
      state.user = user;
      state.userStatus.loading = false;
      state.userStatus.error = null;
    },
    aspirantTypeRecieved: (user, action) => {
      user.aspirantTypes = action.payload.map(a => ({ value: a.icon, label: a.name }));
      user.loading = false;
    },
    // GET Categories
    getCategoriesFailure(state, action) {
        state.categories = [];
    },
    getCategoriesSuccess(state, action) {
        state.categories = action.payload;
    },
    // Start user CRUD
    startUserCrudStatus(state) {
        state.userCrud = true;
    },
    endUserCrudStatus(state) {
        state.userCrud = false;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    resetUserAction
} = slice.actions;
// ----------------------------------------------------------------------

export function getUsers(params) {
  return async (dispatch) => {
    dispatch(slice.actions.getUsersStart());
    try {
      const response = await axios({
        method: "get",
        url: API_ENDPOINTS.user.list,
        params
      });
      dispatch(slice.actions.getUsersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.getUsersFailure(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUser(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.getUserstart());
    try {
      const response = await axios.get(API_ENDPOINTS.user.details, {
        params: {
          userId,
        },
      });
      dispatch(slice.actions.getUsersuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.getUserFailure(error));
    }
  };
}

export function getAspirantTypes() {
  return async (dispatch) => {
    try {
      const response = await axios.get(API_ENDPOINTS.user.aspirantTypes);
      
      dispatch(slice.actions.aspirantTypeRecieved(response.data));
    } catch (error) {
      console.error(error);
    }
  };
}

export const addUser =  async (data)=> {
    // dispatch(slice.actions.startUserCrudStatus());
    try {
        const response = await axios.post(API_ENDPOINTS.user.add, data);
        // dispatch(slice.actions.endUserCrudStatus());
        return response.data;
    } catch (error) {
        // dispatch(slice.actions.endUserCrudStatus());
        return error;
    }
  }

  export const editUser = async (data) => {
      try {
        const response = await axios.put(API_ENDPOINTS.user.edit, data);
        return response.data;
      } catch (error) {
        return error;
      }
  }
export function getCategories() {
    return async (dispatch) => {
      try {
        const response = await axios.get(API_ENDPOINTS.user.categories);
        dispatch(slice.actions.getCategoriesSuccess(response.data));
      } catch (error) {
        console.error(error);
        dispatch(slice.actions.getCategoriesFailure(error));
      }
    };
  }
