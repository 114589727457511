import axios from 'axios';
// config
import {HOST_API, AUTH_TOKEN} from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers: {
    common: {        // can be common or any other method
      'auth-token': AUTH_TOKEN
    }
  }
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
/* login: '/api/auth/login', */
export const API_ENDPOINTS = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  job: {
    list: '/admin/job/list',
    create: '/admin/job',
    details: '/admin/job/details',
  },
  applicant: {
    list : 'admin/job/applications',
  },
  calendar: '/api/calendar',
  auth: {
    me: '/admin/checkLogin',
    login: '/admin/signIn',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
  profile: {
    new: '/admin/profile/new',
    list: '/admin/profile/list',
    details: '/admin/profile/details',
    search: '/admin/profile/search',
    update: {
      general: '/admin/profile/general',
      attributes: '/admin/profile/attributes',
      social: '/admin/profile/social',
      charges: '/admin/profile/charges',
      addImage: '/admin/profile/addimages',
      addVideoUrl: '/admin/profile/addvideourl',
      deleteImage: '/admin/profile/deleteimage',
      deleteVideoUrl: '/admin/profile/deleteVideoUrl',
      addExperience: '/admin/profile/add-experience',
      updateExperience: '/admin/profile/update-experience',
      deleteExperience: '/admin/profile/delete-experience',
      uploadLogo: '/admin/profile/upload-logo',
    },
  },
  report:{
    usersSummary: '/admin/report/users/summary',
    profilesSummary: '/admin/report/profiles/summary',
    jobsSummary: '/admin/report/jobs/summary',
    applicationsSummary: '/admin/report/applications/summary',
  },
  user: {
    list: '/admin/user/list',
    details: '/admin/user/details',
    search: '/admin/user/search',
    categories: '/admin/user/categories',
    add: '/admin/user/add',
    edit: '/admin/user/edit',
    aspirantTypes: '/admin/user/aspirants',
  },
  language: {
    list: '/language/list'
  },
  city: {
    // todo: should be renames to locations
    list: '/city/all',
    states: '/city'
  },
  attributes: {
    list: '/user/attributes'
  }
};
