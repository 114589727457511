import { createSlice } from '@reduxjs/toolkit';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  languages: [],
  languagesStatus: {
    loading: false,
    empty: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    // GET PROFILES
    getLanguagesStart(state) {
      state.languagesStatus.loading = true;
      state.languagesStatus.empty = false;
      state.languagesStatus.error = null;
    },
    getLanguagesFailure(state, action) {
      state.languagesStatus.loading = false;
      state.languagesStatus.empty = false;
      state.languagesStatus.error = action.payload;
    },
    getLanguagesSuccess(state, action) {
      const languages = action.payload;  
      state.languages = languages.languages;

      state.languagesStatus.loading = false;
      state.languagesStatus.empty = !languages.length;
      state.languagesStatus.error = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getLanguages(params) {
  return async (dispatch) => {
    dispatch(slice.actions.getLanguagesStart());
    try {
      const response = await axios({
        method: "get",
        url: API_ENDPOINTS.language.list,
        params
      });
      dispatch(slice.actions.getLanguagesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.getLanguagesFailure(error));
    }
  };
}
