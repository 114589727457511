import { createSlice } from "@reduxjs/toolkit";
import { func } from "prop-types";
// utils
import axios, { API_ENDPOINTS } from "src/utils/axios";

const initialState = {
  jobs: [],
  job:null,
  jobsStatus:{
    isLoading: false,
    error: null,
  },
  jobStatus:{
    isLoading:false,
    error:null,
  }
};

const slice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {

    // JOBS
    getJobsStart(state) {
      state.jobsStatus.isLoading = true;
      state.jobsStatus.error = null;
    },
    getJobsFailure(state,action) {
      state.jobsStatus.isLoading = false;
      state.jobsStatus.error = action.payload;
    },
    getJobsSuccess(state,action) {
      state.jobs = action.payload;
      state.jobsStatus.isLoading = false;
      state.jobsStatus.error = null;
    },

    // JOB
    getJobStart(state) {
      state.jobStatus.isLoading = true;
      state.jobStatus.error = null;
    },
    getJobFailure(state,action) {
      state.jobStatus.isLoading = false;
      state.jobStatus.error = action.payload;
    },
    getJobSuccess(state,action) {
      state.job = action.payload;
      state.jobStatus.isLoading = false;
      state.jobStatus.error = null;
    },
  },
});

export default slice.reducer;

export const getJobs = (params) => async (dispatch) => {
  dispatch(slice.actions.getJobsStart());
  try {
    const response = await axios({
      method: "get",
      url: API_ENDPOINTS.job.list,
      params,
    });
    dispatch(slice.actions.getJobsSuccess(response.data));
  } catch (error) {
    dispatch(slice.actions.getJobsFailure(error));
  }
};

export const getJob = (jobId) => async (dispatch) => {
  dispatch(slice.actions.getJobStart());
  try {
    const response = await axios.get(`${API_ENDPOINTS.job.details}/${jobId}`);
    
    dispatch(slice.actions.getJobSuccess(response.data.data));
  } catch (error) {
    dispatch(slice.actions.getJobFailure(error));
  }
};

export const clearJob = () => async (dispatch) =>{
  try {
    dispatch(slice.actions.getJobSuccess(null));
  } catch (error) {
    dispatch(slice.actions.getJobFailure(error));
  }
}
