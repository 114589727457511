import {useMemo} from 'react';
// routes
import {paths} from 'src/routes/paths';
// locales
import {useLocales} from 'src/locales';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{width: 1, height: 1}}/>
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const {t} = useLocales();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('overview'),
        items: [
          {
            title: t('app'),
            path: paths.dashboard.root,
            icon: ICONS.dashboard
          },
          {
            title: t('analytics'),
            path: paths.dashboard.general.analytics,
            icon: ICONS.analytics,
            // disabled: true
          },
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: t('management'),
        items: [
          // USER
          {
            title: t('user'),
            path: paths.dashboard.user.list,
            icon: ICONS.user,
            // children: [
            //   // {title: t('profile'), path: paths.dashboard.user.root},
            //   {title: t('list'), path: paths.dashboard.user.list},
            //   {title: t('create'), path: paths.dashboard.user.new},
            //   {title: t('edit'), path: paths.dashboard.user.demo.edit},
            //   // {title: t('cards'), path: paths.dashboard.user.cards},
            //   {title: t('account'), path: paths.dashboard.user.account},
            // ],
          },
          {
            title: t('profile'),
            path: paths.dashboard.profile.root,
            icon: ICONS.user,
            // children: [
            //   {title: t('list'), path: paths.dashboard.profile.root},
            //   // {title: t('users'), path: paths.dashboard.user.list},
            // ],
          },

          // INVOICE
          {
            title: t('invoice'),
            path: paths.dashboard.invoice.root,
            icon: ICONS.invoice,
            // children: [
            //   {title: t('list'), path: paths.dashboard.invoice.root},
            //   {title: t('details'), path: paths.dashboard.invoice.demo.details},
            //   {title: t('create'), path: paths.dashboard.invoice.new},
            //   {title: t('edit'), path: paths.dashboard.invoice.demo.edit},
            // ],
            disabled:true,
          },

          // JOB
          {
            title: t('job'),
            path: paths.dashboard.job.root,
            icon: ICONS.job,
            // children: [
            //   {title: t('list'), path: paths.dashboard.job.root},
            //   {title: t('details'), path: paths.dashboard.job.demo.details, disabled: true},
            //   {title: t('create'), path: paths.dashboard.job.new, disabled: true},
            //   {title: t('edit'), path: paths.dashboard.job.demo.edit, disabled: true},
            // ]
          },

          // FILE MANAGER
          {
            title: t('file_manager'),
            path: paths.dashboard.fileManager,
            icon: ICONS.folder,
            disabled:true,

          },

          // MAIL
          {
            title: t('mail'),
            path: paths.dashboard.mail,
            icon: ICONS.mail,
            info: <Label color="error">+32</Label>,
            disabled:true,

          },

          // CHAT
          {
            title: t('chat'),
            path: paths.dashboard.chat,
            icon: ICONS.chat,
            disabled: true
          },

          // CALENDAR
          {
            title: t('calendar'),
            path: paths.dashboard.calendar,
            icon: ICONS.calendar,
            disabled:true,

          },

          // KANBAN
          {
            title: t('kanban'),
            path: paths.dashboard.kanban,
            icon: ICONS.kanban,
            disabled:true,

          },
        ],
      },

    ],
    [t]
  );

  return data;
}
